import { useMediaQuery } from '@kaliber/use-media-query'
import { useKeenSlider } from 'keen-slider/react'
import { useTranslate } from '/machinery/I18n'
import { useUrls } from '/domain/useUrls'
import { ButtonGhost } from '/features/buildingBlocks/Button'
import { HeadingLg } from '/features/buildingBlocks/Heading'
import { JobListingCardHighlighted } from '/features/buildingBlocks/JobListing'
import { Icon } from '/features/buildingBlocks/Icon'
import { trackInteraction } from '/machinery/tracking/pushToDataLayer'

import mediaStyles from '/cssGlobal/media.css'
import styles from './HighlightedJobs.css'

import iconChevronRight from '/images/icons/chevron-right.raw.svg'
import iconChevronLeft from '/images/icons/chevron-left.raw.svg'

export function HighlightedJobs({ relevantJobs, locationType, layoutClassName = undefined }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)
  const { __ } = useTranslate()
  const urls = useUrls()
  const title = locationType === 'shops' ? __`job-detail-highlighted-jobs-title-shops` : locationType === 'offices' ? __`job-detail-highlighted-jobs-title-offices` : __`job-detail-highlighted-jobs-title`
  const label = locationType === 'shops' ? __`job-detail-highlighted-jobs-label-shops` : locationType === 'offices' ? __`job-detail-highlighted-jobs-label-offices` : __`job-detail-highlighted-jobs-label`
  const link = locationType === 'shops' ? urls.jobOverview.shops() : locationType === 'offices' ? urls.jobOverview.offices() : urls.jobOverview.all()

  const [sliderRef, instanceRef] = useKeenSlider({
    loop: relevantJobs.length > 1,
    slides: {
      origin: relevantJobs.length === 1 ? 'center' : 'auto',
      perView: isViewportMd ? 2 : 1.2,
      spacing: isViewportMd ? 30 : 15,
    },
    dragChecked(slider) {
      trackInteraction({
        title: title ?? 'highlighted-jobs',
        action: 'vertical scrolled',
        type: 'slided',
        index: slider.track.details.rel
      })
    },
    selector: x => x.childNodes,
  })

  React.useEffect(
    () => {
      instanceRef.current.update()
    },
    [instanceRef, isViewportMd]
  )

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <HeadingLg h='2' {...{ title }} layoutClassName={styles.headingLayout} />

      <div className={styles.sliderAndButtons}>
        {relevantJobs.length > 2 && (
          <IconButton
            icon={iconChevronLeft}
            ariaLabel={__`show-previous-slide`}
            dataX='click-to-go-previous-slide'
            onClick={() => instanceRef.current?.prev()}
            layoutClassName={styles.sliderButtonLayout}
          />
        )}

        <div className={styles.sliderContainer}>
          <div className={styles.slider} ref={sliderRef}>
            {relevantJobs.map(({ _source: job }, i) =>
              <JobListingCardHighlighted key={i} {...{ job }} layoutClassName={styles.cardLayout} />
            )}
          </div>
        </div>

        {relevantJobs.length > 2 && (
          <IconButton
            icon={iconChevronRight}
            ariaLabel={__`show-next-slide`}
            dataX='click-to-go-next-slide'
            onClick={() => instanceRef.current?.next()}
            layoutClassName={styles.sliderButtonLayout}
          />
        )}
      </div>

      <ButtonGhost href={link} dataX='link-to-jobsOverview' layoutClassName={styles.buttonLayout}>{label}</ButtonGhost>
    </div>
  )
}

function IconButton({ icon, dataX, ariaLabel, onClick, layoutClassName }) {
  return (
    <button
      data-x={dataX}
      aria-label={ariaLabel}
      className={cx(styles.componentIconButton, layoutClassName)}
      {...{ onClick }}
    >
      <Icon {...{ icon }} />
    </button>
  )
}
